<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-2">
        <label>Nº Chamado</label>
        <input type="text" class="form-control" v-model="filters.id" />
      </div>
      <div class="col-md-2">
        <label>Status</label>
        <select class="form-control" v-model="filters.status">
          <option value=""></option>
          <option value="1">Aguardando Atendimento</option>
          <option value="2">Atendimento N1</option>
          <option value="3">Atendimento N2</option>
          <option value="4">Aguardando Desenvolvimento</option>
          <option value="10">Aguardando Retorno do Cliente</option>
          <option value="11">Em Homologação</option>
        </select>
      </div>
      <div class="col-md-2">
        <label>Título</label>
        <input type="text" class="form-control" v-model="filters.title" />
      </div>
      <div class="col-md-2">
        <label>Solicitante</label>
        <input type="text" class="form-control" v-model="filters.user_id" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-2">
        <button class="btn btn-primary" @click="getMyCalls">Filtrar</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card content-crud">
          <div class="ibox">
            <div class="ibox-title" style="padding: 16px">
              <h3>Meus Chamados</h3>
            </div>
            <div class="ibox-content">
              <div class="table-responsive" style="padding: 10px">
                <table
                  class="VueTables__table table table-striped table-bordered table-hover"
                  style="padding: 10px"
                >
                  <thead>
                    <tr>
                      <th scope="col">Nº Chamado</th>
                      <th scope="col">TÍTULO</th>
                      <th scope="col">PRIORIDADE</th>
                      <th scope="col">STATUS</th>
                      <th scope="col">SOLICITANTE</th>
                      <th scope="col">SISTEMA</th>
                      <th scope="col">DATA DE ABERTURA</th>
                      <th scope="col">PRAZO</th>
                      <th scope="col">ÚLTIMA INTERAÇÃO</th>
                      <th scope="col">DATA DA ÚLTIMA INTERAÇÃO</th>
                      <th scope="col">AÇÕES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="my_call in my_calls" :key="my_call.id">
                      <td>{{ my_call.id }}</td>
                      <td>
                        {{ my_call.title.replace(/<\/?p>/g, "") }}
                      </td>
                      <td>{{ my_call.priority }}</td>
                      <td>{{ my_call.status_calls.description }}</td>
                      <td>
                        {{ my_call.user_model ? my_call.user_model.name : "" }}
                      </td>
                      <td>{{ my_call.project.name }}</td>
                      <td>{{ my_call.created_at_f }}</td>
                      <td>{{ my_call.deadline ? my_call.deadline_f: "Não existe prazo para esse chamado " }}</td>
                      <td>
                        {{
                          my_call.last_interaction
                            ? my_call.last_interaction.user.name
                            : "Não houve interações neste chamado"
                        }}
                      </td>
                      <td>
                        {{
                          my_call.last_interaction
                            ? my_call.last_interaction.created_at_f
                            : "Não houve interações neste chamado"
                        }}
                      </td>
                      <td>
                        <a
                          :href="'/calls/view/' + my_call.id"
                          title="Visualizar"
                          class="btn btn-crud view"
                          target="_blank"
                        >
                          <i class="fas fa-eye"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import { Pagination } from "vue-pagination-2";

export default {
  data: function () {
    return {
      filters: {},
      my_calls: [],
    };
  },
  components: {
    BaseCrud,
    vSelect,
    Pagination,
  },

  methods: {
    removePTags(text) {
      return text.replace(/<\/?p>/g, "");
    },
    getMyCalls() {
      const self = this;

      let api = self.$store.state.api + "my-calls";

      self.$loading(true);
      axios
        .post(api, self.filters)
        .then((response) => {
          self.my_calls = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
  },
  mounted() {
    this.getMyCalls();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>